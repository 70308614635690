import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="162" height="10" viewBox="0 0 162 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.0459 8.72465C26.3005 7.97829 1.95225 10.1013 0.557189 9.99622C-0.920338 9.75296 0.86644 3.89821 2.14467 2.96941C2.92811 2.4055 2.87313 2.81461 9.62852 2.4055C9.62852 2.4055 19.8131 1.80842 32.3343 1.11182C32.3343 1.11182 52.4493 0.210661 80.1444 0.000575625C82.3229 -0.0160101 80.5979 0.332289 83.1613 0.210661C88.6247 -0.0381242 100.514 0.431804 104.651 0.232775C107.97 0.072447 108.685 0.0613902 111.853 0.365461C114.031 0.575547 132.311 1.09523 134.339 0.780103C134.833 0.702703 135.191 0.75246 135.184 0.879617C135.328 0.890674 147.307 1.64809 147.362 1.76419C147.444 1.89687 147.808 1.9798 148.159 1.91346C148.805 1.7863 161.759 2.17883 161.931 2.56583C162.425 3.67154 160.164 8.03911 158.577 9.05083C156.935 10.0957 148.97 8.26025 141.932 8.57538C109.111 6.94445 109.365 7.35909 103.881 7.57471C103.125 7.17112 100.143 8.03911 98.3695 7.34804C97.6273 7.05502 92.2945 6.94445 91.0987 7.19324C90.8444 7.24852 88.9408 7.23746 86.1919 7.19876C84.8175 7.18218 83.23 7.15454 81.5325 7.13242C79.2647 7.09925 62.1048 6.94998 59.9538 7.59129C54.1674 6.94998 35.5505 8.33765 30.0459 8.72465Z" fill="#FFBA00" />
    </svg>

  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="162" height="10" viewBox="0 0 162 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.0459 8.72465C26.3005 7.97829 1.95225 10.1013 0.557189 9.99622C-0.920338 9.75296 0.86644 3.89821 2.14467 2.96941C2.92811 2.4055 2.87313 2.81461 9.62852 2.4055C9.62852 2.4055 19.8131 1.80842 32.3343 1.11182C32.3343 1.11182 52.4493 0.210661 80.1444 0.000575625C82.3229 -0.0160101 80.5979 0.332289 83.1613 0.210661C88.6247 -0.0381242 100.514 0.431804 104.651 0.232775C107.97 0.072447 108.685 0.0613902 111.853 0.365461C114.031 0.575547 132.311 1.09523 134.339 0.780103C134.833 0.702703 135.191 0.75246 135.184 0.879617C135.328 0.890674 147.307 1.64809 147.362 1.76419C147.444 1.89687 147.808 1.9798 148.159 1.91346C148.805 1.7863 161.759 2.17883 161.931 2.56583C162.425 3.67154 160.164 8.03911 158.577 9.05083C156.935 10.0957 148.97 8.26025 141.932 8.57538C109.111 6.94445 109.365 7.35909 103.881 7.57471C103.125 7.17112 100.143 8.03911 98.3695 7.34804C97.6273 7.05502 92.2945 6.94445 91.0987 7.19324C90.8444 7.24852 88.9408 7.23746 86.1919 7.19876C84.8175 7.18218 83.23 7.15454 81.5325 7.13242C79.2647 7.09925 62.1048 6.94998 59.9538 7.59129C54.1674 6.94998 35.5505 8.33765 30.0459 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)